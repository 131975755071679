<template>
  <AppField :label="label" :description="description">
    <div class="inline-flex rounded-sm border-[1px] border-zinc-700 p-[2px]">
      <AppRadioButton
        v-for="option in options"
        :key="option.value"
        :value="option.value"
        :label="option.label"
        v-model="model"
      ></AppRadioButton>
    </div>
  </AppField>
</template>

<script setup>
import { defineProps, defineModel, onMounted } from "vue";
import AppField from "./AppField.vue";
import AppRadioButton from "./AppRadioButton.vue";

const model = defineModel();

const props = defineProps({
  label: {
    type: String,
    default: "Field",
  },
  description: {
    type: String,
    default: "",
  },
  options: {
    type: Array,
    default: () => [],
  },
});

onMounted(() => {
  model.value = props.options[0].value;
});
</script>

<style></style>
