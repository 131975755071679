const transactionTypes = [
  {
    value: "BUY",
    label: "Buy",
  },
  {
    value: "SELL",
    label: "Sell",
  },
];

const cashTransactionTypes = [
  {
    value: "BUY",
    label: "Inject",
  },
  {
    value: "SELL",
    label: "Withdraw",
  },
];

export { transactionTypes, cashTransactionTypes };
