<template>
  <AppField
    :label="props.label"
    :description="props.description"
    class="relative"
  >
    <div class="relative inline-block text-left">
      <div>
        <input
          v-model="inputDateLabel"
          @blur="handleInputBlur"
          @input="handleInputChange"
          type="text"
          placeholder="YYYY-MM-DD"
          class="h-8 rounded-sm border border-neutral-700 bg-neutral-900 pe-2 ps-2 font-normal text-zinc-200 shadow-none ring-0 placeholder:text-zinc-400 hover:border-neutral-600 focus:border-neutral-700 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2 focus:ring-offset-[#181b1f]"
        />
        <button
          @click="togglePopover"
          class="absolute inset-y-0 right-0 flex items-center px-2"
        >
          <img
            src="../assets/icons/calendar.png"
            alt="Date Picker Calendar"
            class="size-4"
          />
        </button>
      </div>
      <Transition
        enter-active-class="transition ease-out duration-100"
        enter-from-class="transform opacity-0 scale-95"
        enter-to-class="transform opacity-100 scale-100"
        leave-active-class="transition ease-in duration-75"
        leave-from-class="transform opacity-100 scale-100"
        leave-to-class="transform opacity-0 scale-95"
      >
        <Popover v-if="isPopoverOpen">
          <div
            class="absolute bottom-full left-0 z-50 w-64 origin-bottom-right rounded-sm border border-neutral-700 bg-[#181b1f] focus:outline-none"
          >
            <div class="p-2">
              <AppCalendar
                :selectedDate="tempSelectedDate"
                @select-date="handleTempDateSelect"
              />
              <div class="mt-4 flex space-x-2">
                <select
                  v-model="tempSelectedHour"
                  class="relative h-8 w-full rounded-sm border border-neutral-700 bg-neutral-900 pe-2 ps-2 text-left font-normal shadow-none ring-0 hover:border-neutral-600 focus:border-neutral-700 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2 focus:ring-offset-[#181b1f]"
                >
                  <option v-for="hour in hours" :key="hour" :value="hour">
                    {{ hour }}
                  </option>
                </select>
                <select
                  v-model="tempSelectedMinute"
                  class="relative h-8 w-full rounded-sm border border-neutral-700 bg-neutral-900 pe-2 ps-2 text-left font-normal shadow-none ring-0 hover:border-neutral-600 focus:border-neutral-700 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2 focus:ring-offset-[#181b1f]"
                >
                  <option
                    v-for="minute in minutes"
                    :key="minute"
                    :value="minute"
                  >
                    {{ minute }}
                  </option>
                </select>
              </div>
              <div class="mt-4 flex justify-between space-x-2">
                <AppButton small secondary @click="cancelSelection"
                  >Cancel</AppButton
                >
                <AppButton
                  small
                  :disabled="!tempSelectedDate"
                  @click="applySelection"
                  >Apply</AppButton
                >
              </div>
            </div>
          </div>
        </Popover>
      </Transition>
    </div>
  </AppField>
</template>

<script setup>
import { defineProps, ref, defineModel, computed } from "vue";
import { Popover } from "@headlessui/vue";
import AppCalendar from "./AppCalendar.vue";
import AppButton from "./AppButton.vue";
import AppField from "./AppField.vue";

const isPopoverOpen = ref(false);

const tempSelectedDate = ref(null);
const tempSelectedHour = ref("00");
const tempSelectedMinute = ref("00");

const selectedDate = ref(null);
const selectedHour = ref("00");
const selectedMinute = ref("00");

const hours = Array.from({ length: 24 }, (_, i) => String(i).padStart(2, "0"));
const minutes = Array.from({ length: 60 }, (_, i) =>
  String(i).padStart(2, "0"),
);

const model = defineModel();

const props = defineProps({
  label: {
    type: String,
    required: true,
  },
  description: {
    type: String,
    default: "",
  },
});

const togglePopover = () => {
  isPopoverOpen.value = !isPopoverOpen.value;
  if (isPopoverOpen.value) {
    tempSelectedDate.value = selectedDate.value;
    tempSelectedHour.value = selectedHour.value;
    tempSelectedMinute.value = selectedMinute.value;
  }
};

const updateInputDate = () => {
  if (selectedDate.value) {
    let date = new Date(selectedDate.value);
    date.setHours(selectedHour.value);
    date.setMinutes(selectedMinute.value);
    model.value = date.toISOString();
  }
};

const inputDateLabel = computed(() => {
  if (selectedDate.value) {
    return `${selectedDate.value.toISOString().split("T")[0]} ${selectedHour.value}:${selectedMinute.value}`;
  } else {
    return "";
  }
});

const handleTempDateSelect = (date) => {
  tempSelectedDate.value = date;
};

const handleInputBlur = () => {
  isPopoverOpen.value = false;
};

const handleInputChange = (event) => {
  const [datePart, timePart] = event.target.value.split(" ");
  const date = new Date(datePart);
  if (!isNaN(date)) {
    selectedDate.value = date;
    if (timePart) {
      const [hour, minute] = timePart.split(":");
      selectedHour.value = hour;
      selectedMinute.value = minute;
    }
  }
};

const applySelection = () => {
  selectedDate.value = tempSelectedDate.value;
  selectedHour.value = tempSelectedHour.value;
  selectedMinute.value = tempSelectedMinute.value;
  updateInputDate();
  isPopoverOpen.value = false;
};

const cancelSelection = () => {
  isPopoverOpen.value = false;
};
</script>

<style scoped></style>
