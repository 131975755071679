<template>
  <div class="flex h-screen w-full flex-col items-center justify-center gap-6">
    <p class="text-3xl font-black text-white">Looks like you are lost</p>
    <AppButton @click="onClick">Get me back on tracks</AppButton>
  </div>
</template>

<script setup>
import AppButton from "@/components/AppButton.vue";

const onClick = () => {
  window.location = "#/";
};
</script>

<style></style>
