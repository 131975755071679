<template>
  <AppField
    :label="props.label"
    :description="props.description"
    class="relative"
  >
    <Listbox :model-value="props.modelValue" @update:modelValue="onUpdate">
      <ListboxButton
        class="relative h-8 w-full rounded-sm border border-neutral-700 bg-neutral-900 pe-2 ps-2 text-left font-normal shadow-none ring-0 hover:border-neutral-600 focus:border-neutral-700 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2 focus:ring-offset-[#181b1f]"
        :class="[label ? 'text-zinc-200' : 'text-zinc-400']"
      >
        <span>{{ label ? label : "Select..." }}</span>
        <span class="absolute inset-y-0 right-0 flex items-center pr-2">
          <img
            src="../assets/icons/caret-down.png"
            alt="Select Caret Down"
            class="size-5"
          />
        </span>
      </ListboxButton>
      <transition
        leave-active-class="transition duration-100 ease-in"
        leave-from-class="opacity-100"
        leave-to-class="opacity-0"
      >
        <ListboxOptions
          class="absolute z-50 max-h-60 w-full overflow-auto bg-neutral-900 shadow-lg shadow-black"
        >
          <ListboxOption
            v-for="option in props.options"
            :key="option.label"
            :value="option.value"
            class="cursor-pointer p-2 hover:bg-zinc-700"
          >
            {{ option.label }}
          </ListboxOption>
        </ListboxOptions>
      </transition>
    </Listbox>
  </AppField>
</template>

<script setup>
import { defineProps, defineEmits, computed } from "vue";
import AppField from "./AppField.vue";
import {
  Listbox,
  ListboxOptions,
  ListboxOption,
  ListboxButton,
} from "@headlessui/vue";

const props = defineProps({
  label: {
    type: String,
    required: true,
  },
  description: {
    type: String,
    default: "",
  },
  options: {
    type: Array,
  },
  placeholder: {
    type: String,
    default: "Select...",
  },
  modelValue: {
    type: [String, Number, Array],
  },
});

const emit = defineEmits(["update:modelValue", "change"]);

const onUpdate = (value) => {
  emit("update:modelValue", value);
  emit("change");
};

const label = computed(() => {
  return props.options.find((option) => option.value === props.modelValue)
    ?.label;
});
</script>

<style scoped>
[aria-selected="true"] {
  @apply bg-zinc-700;
}
</style>
