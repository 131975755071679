export default class Transaction {
  constructor() {
    this.init();
  }

  init() {
    this.account = null;
    this.assetType = "CRYPTO";
    this.asset = null;
    this.type = "BUY";
    this.amount = null;
    this.date = null;
    this.currency = null;
  }

  getCreationFormat() {
    if (this.assetType !== "SAVING") {
      return {
        account_id: this.account,
        assetType: this.assetType,
        asset_id: this.asset.value,
        amount: this.type === "BUY" ? this.amount : -this.amount,
        date: this.date,
      };
    } else {
      return {
        account_id: this.account,
        amount: this.type === "BUY" ? this.amount : -this.amount,
        currency: this.currency,
        date: this.date,
      };
    }
  }

  reset() {
    this.init();
  }

  isValid() {
    if (this.assetType !== "SAVING") {
      return (
        this.account &&
        this.assetType &&
        this.asset &&
        this.type &&
        this.amount > 0 &&
        this.date
      );
    } else {
      return (
        this.account &&
        this.assetType &&
        this.currency &&
        this.amount > 0 &&
        this.date
      );
    }
  }
}
