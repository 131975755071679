import axios from "axios";

async function createAccount(accountName, token) {
  try {
    let res = await axios.post(
      `${process.env.VUE_APP_BACKEND_URL}/accounts`,
      { name: accountName },
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      },
    );
    return res.data;
  } catch (err) {
    console.error(err);
    throw new Error("Error while creating account");
  }
}

async function listAccounts(token) {
  try {
    let res = await axios.get(`${process.env.VUE_APP_BACKEND_URL}/accounts`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    return res.data;
  } catch (err) {
    console.error("Error while recovering accounts");
    console.error(err);
    return [];
  }
}

export { createAccount, listAccounts };
