<template>
  <AppField :label="label" :description="description" class="relative">
    <Combobox
      :model-value="props.modelValue"
      @update:modelValue="onUpdate"
      :disabled="disabled"
      by="id"
    >
      <div class="relative">
        <div
          class="relative w-full cursor-default overflow-hidden bg-neutral-900 text-left"
        ></div>
        <ComboboxInput
          @change="onChange"
          class="relative h-8 w-full rounded-sm border border-neutral-700 bg-neutral-900 pe-2 ps-2 text-left font-normal shadow-none ring-0 hover:border-neutral-600 focus:border-neutral-700 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2 focus:ring-offset-[#181b1f] disabled:hover:border-neutral-700"
          :class="[props.modelValue ? 'text-zinc-200' : 'text-zinc-400']"
          :display-value="(option) => option?.label"
        />
        <ComboboxButton
          class="absolute inset-y-0 right-0 flex cursor-pointer items-center pr-2 disabled:cursor-default"
          :disabled="disabled"
        >
          <img
            src="../assets/icons/caret.png"
            alt="Autocomplete Caret"
            class="size-4"
          />
        </ComboboxButton>
      </div>
      <TransitionRoot
        leave="transition ease-in duration-100"
        leaveFrom="opacity-100"
        leaveTo="opacity-0"
        @after-leave="query = ''"
      >
        <ComboboxOptions
          class="grafana-scrollbar absolute z-50 max-h-60 w-full overflow-auto bg-neutral-900 shadow-lg shadow-black"
        >
          <div
            v-if="filteredOptions.length === 0 && query !== ''"
            class="relative cursor-default select-none px-4 py-2 text-zinc-400"
          >
            Nothing found.
          </div>
          <ComboboxOption
            v-for="option in filteredOptions"
            :key="option.value"
            :value="option"
            class="cursor-pointer p-2 hover:bg-zinc-700"
          >
            {{ option.label }}
          </ComboboxOption>
        </ComboboxOptions>
      </TransitionRoot>
    </Combobox>
  </AppField>
</template>

<script setup>
import { defineProps, ref, computed, defineEmits } from "vue";
import AppField from "./AppField.vue";
import {
  Combobox,
  ComboboxInput,
  ComboboxOptions,
  ComboboxOption,
  ComboboxButton,
  TransitionRoot,
} from "@headlessui/vue";

const props = defineProps({
  label: {
    type: String,
    required: true,
  },
  description: {
    type: String,
    default: "",
  },
  options: {
    type: Array,
    required: true,
    default: () => [],
  },
  placeholder: {
    type: String,
    default: "Select...",
  },
  disabled: {
    type: Boolean,
    default: false,
  },
  modelValue: {
    type: Object,
  },
});

const emit = defineEmits(["update:modelValue", "change"]);

const query = ref("");

function onUpdate(value) {
  emit("update:modelValue", value);
}

function onChange($event) {
  query.value = $event.target.value;
  emit("change", query.value);
}

const filteredOptions = computed(() =>
  query.value === ""
    ? props.options
    : props.options.filter((option) => {
        return option.label.toLowerCase().includes(query.value.toLowerCase());
      }),
);
</script>
