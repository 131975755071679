import axios from "axios";

async function listExchanges(token) {
  try {
    let res = await axios.get(`${process.env.VUE_APP_BACKEND_URL}/exchanges`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    return res.data;
  } catch (err) {
    console.error("Error while recovering exchanges");
    console.error(err);
    return [];
  }
}

export { listExchanges };
