<template>
  <button
    class="m-1 box-border line-clamp-2 inline-flex h-8 items-center rounded-sm font-medium shadow-sm disabled:cursor-not-allowed disabled:bg-zinc-800 disabled:text-zinc-500"
    :class="[
      props.small ? 'h-6 px-2 pe-2 ps-2 text-sm' : 'text-md h-8 px-4 pe-4 ps-4',
      props.secondary
        ? 'bg-zinc-700 hover:bg-zinc-600'
        : 'bg-blue-500 hover:bg-blue-400',
    ]"
    :disabled="disabled"
  >
    <slot></slot>
  </button>
</template>

<script setup>
import { defineProps } from "vue";

const props = defineProps({
  disabled: {
    type: Boolean,
    default: false,
  },
  small: {
    type: Boolean,
    default: false,
  },
  secondary: {
    type: Boolean,
    default: false,
  },
});
</script>

<style></style>
