import axios from "axios";

async function listAssets(type, exchange, search, token) {
  try {
    let res = await axios.get(`${process.env.VUE_APP_BACKEND_URL}/assets`, {
      params: {
        type: type,
        exchange: exchange,
        search: search,
      },
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    return res.data;
  } catch (err) {
    console.error("Error while recovering assets");
    console.error(err);
    return [];
  }
}

export { listAssets };
