<template>
  <div class="relative flex flex-[0_0_auto] justify-between text-center">
    <input
      type="radio"
      id="radiobutton"
      class="peer absolute z-20 h-full w-full cursor-pointer opacity-0"
      :value="value"
      v-model="model"
    />
    <label
      for="radiobutton"
      class="z-10 flex h-7 grow cursor-pointer items-center justify-center whitespace-nowrap rounded-sm bg-transparent px-4 text-sm font-medium text-stone-400 peer-checked:bg-zinc-700 peer-checked:text-stone-300"
      >{{ label }}</label
    >
  </div>
</template>

<script setup>
import { defineProps, defineModel } from "vue";

const model = defineModel();

defineProps({
  value: {
    type: String,
    default: null,
  },
  label: {
    type: String,
    default: "Field",
  },
});
</script>

<style></style>
