export default [
  {
    value: "CRYPTO",
    label: "Crypto",
  },
  {
    value: "STOCK",
    label: "Stock",
  },
  {
    value: "ETF",
    label: "ETF",
  },
  {
    value: "SAVING",
    label: "Saving",
  },
];
