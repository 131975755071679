import axios from "axios";

async function createTransaction(transaction, token) {
  let resource = transaction.currency ? "savings" : "transactions";
  try {
    let res = await axios.post(
      `${process.env.VUE_APP_BACKEND_URL}/${resource}`,
      transaction,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      },
    );
    return res.data;
  } catch (err) {
    console.error(err);
    throw new Error("Error while creating transactions");
  }
}

export { createTransaction };
