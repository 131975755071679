<template>
  <div class="flex flex-col items-center gap-4 p-2">
    <div
      class="flex flex-col gap-4 rounded-md border border-blue-400 p-4 font-medium text-gray-300"
    >
      Create a transaction
      <div class="flex flex-col gap-4">
        <AppSelect
          label="Account"
          :options="accountsValues"
          v-model="transaction.account"
        >
        </AppSelect>
        <AppSelect
          label="Asset Type"
          :options="assetTypes"
          v-model="transaction.assetType"
          @change="onAssetTypeChange"
        ></AppSelect>
        <AppSelect
          v-if="transaction.assetType !== 'SAVING'"
          label="Exchange"
          :options="exchangesValues"
          v-model="transaction.exchange"
          @change="onAssetExchangeChange"
        ></AppSelect>
        <AppAutocomplete
          v-if="transaction.assetType !== 'SAVING'"
          label="Asset"
          :options="assetsValues"
          :disabled="!transaction.assetType || !transaction.exchange"
          v-model="transaction.asset"
          @change="onAssetInputChange"
        ></AppAutocomplete>
        <AppRadioGroup
          label="Type"
          :options="
            transaction.assetType === 'SAVING'
              ? cashTransactionTypes
              : transactionTypes
          "
          v-model="transaction.type"
        ></AppRadioGroup>
        <AppInput
          label="Amount"
          type="number"
          placeholder="0"
          v-model="transaction.amount"
        ></AppInput>
        <AppSelect
          v-if="transaction.assetType === 'SAVING'"
          label="Currency"
          :options="currenciesValues"
          v-model="transaction.currency"
        ></AppSelect>
        <AppDatePicker label="Date" v-model="transaction.date"></AppDatePicker>
      </div>
      <div class="flex justify-between">
        <AppButton secondary="" @click="transaction.reset">Reset</AppButton>
        <AppButton :disabled="!transaction.isValid()" @click="onCreate"
          >Create</AppButton
        >
      </div>
    </div>
  </div>
</template>

<script setup>
import { computed, onMounted, ref } from "vue";
import { useAuth0 } from "@auth0/auth0-vue";
import AppDatePicker from "../components/AppDatePicker.vue";
import AppButton from "../components/AppButton.vue";
import AppInput from "../components/AppInput.vue";
import AppRadioGroup from "../components/AppRadioGroup.vue";
import AppSelect from "../components/AppSelect.vue";
import AppAutocomplete from "../components/AppAutocomplete.vue";

import assetTypes from "../options/assetTypes";
import {
  transactionTypes,
  cashTransactionTypes,
} from "../options/transactionTypes";
import Transaction from "../models/transaction";
import { listAssets } from "../apis/assetAPI";
import { listExchanges } from "../apis/exchangeAPI";
import { createTransaction } from "../apis/transactionAPI";
import { listAccounts } from "../apis/accountAPI";
import { listCurrencies } from "../apis/currencyAPI";

const { getAccessTokenSilently } = useAuth0();

// Variables
const assets = ref([]);
const exchanges = ref([]);
const accounts = ref([]);
const currencies = ref([]);
const transaction = ref(new Transaction());

let token = null;

// Computed
const assetsValues = computed(() => {
  return assets.value
    ? assets.value.map((v) => {
        return {
          value: v.id,
          label: v.name,
        };
      })
    : [];
});

const exchangesValues = computed(() => {
  return exchanges.value
    ? exchanges.value.map((v) => {
        return {
          value: v.code,
          label: v.name,
        };
      })
    : [];
});

const accountsValues = computed(() => {
  return accounts.value
    ? accounts.value.map((v) => {
        return {
          value: v.id,
          label: v.name,
        };
      })
    : [];
});

const currenciesValues = computed(() => {
  return currencies.value
    ? currencies.value.map((v) => {
        return {
          value: v.code,
          label: v.name,
        };
      })
    : [];
});

// Methods
const onAssetInputChange = async (value) => {
  assets.value = await listAssets(
    transaction.value.assetType,
    transaction.value.exchange,
    value,
    token,
  );
};

const onAssetTypeChange = async () => {
  transaction.value.asset = null;
};

const onAssetExchangeChange = async () => {
  transaction.value.asset = null;
  assets.value = await listAssets(
    transaction.value.assetType,
    transaction.value.exchange,
    "",
    token,
  );
};

const onCreate = async () => {
  try {
    await createTransaction(transaction.value.getCreationFormat(), token);
    transaction.value.reset();
  } catch (err) {
    console.error(err);
  }
};

const getToken = () => {
  getAccessTokenSilently()
    .then(async (t) => {
      token = t;
      exchanges.value = await listExchanges(token);
      accounts.value = await listAccounts(token);
      currencies.value = await listCurrencies(token);
    })
    .catch(() => {
      console.log("Not logged yet");
    });
};

// LIfecycle Hooks

onMounted(async () => {
  getToken();
});
</script>

<style>
#app {
  font-family: Inter, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #181b1f;
  color: white;
  width: 100vw;
  min-height: 100vh;
}
</style>
