<template>
  <div class="flex flex-col">
    <div class="mb-2 flex items-center justify-between">
      <button @click="prevMonth" class="text-gray-500 hover:text-gray-700">
        <img
          src="../assets/icons/caret-left.png"
          alt="Previous Month"
          class="size-4"
        />
      </button>
      <span class="text-sm font-semibold">{{
        format(currentMonth, "MMMM yyyy")
      }}</span>
      <button @click="nextMonth" class="text-gray-500 hover:text-gray-700">
        <img
          src="../assets/icons/caret-right.png"
          alt="Next Month"
          class="size-4"
        />
      </button>
    </div>
    <div class="grid grid-cols-7 gap-x-1.5 gap-y-0.5 text-center">
      <div
        v-for="day in daysOfWeek"
        :key="day"
        class="text-sm font-light text-blue-400"
      >
        {{ day }}
      </div>
      <div
        v-for="day in daysInMonth"
        :key="day.date"
        @click="selectDate(day.date)"
        class="flex cursor-pointer items-center justify-center rounded-full px-3 py-1 text-sm"
        :class="[
          day.isToday ? 'border border-blue-500 text-white' : '',
          day.isSelected ? 'bg-blue-500' : '',
        ]"
      >
        {{ day.date.getDate() }}
      </div>
    </div>
  </div>
</template>

<script setup>
import { defineProps, ref, computed, defineEmits } from "vue";
import {
  addDays,
  startOfWeek,
  startOfMonth,
  endOfMonth,
  eachDayOfInterval,
  format,
  isToday,
  isSameDay,
} from "date-fns";

const props = defineProps({
  selectedDate: {
    type: Date,
    default: null,
  },
});

const emit = defineEmits(["select-date"]);

const currentMonth = ref(new Date());

const daysOfWeek = ["Mon", "Tue", "Wed", "Thu", "Fri", "Sat", "Sun"];

const daysInMonth = computed(() => {
  const start = startOfWeek(startOfMonth(currentMonth.value), {
    weekStartsOn: 1,
  });
  const end = endOfMonth(currentMonth.value);
  const endExtended = addDays(end, 6 - end.getDay());
  const days = eachDayOfInterval({ start, end: endExtended });

  return days.map((date) => ({
    date,
    isToday: isToday(date),
    isSelected: props.selectedDate && isSameDay(date, props.selectedDate),
  }));
});

const prevMonth = () => {
  currentMonth.value = new Date(
    currentMonth.value.setMonth(currentMonth.value.getMonth() - 1),
  );
};

const nextMonth = () => {
  currentMonth.value = new Date(
    currentMonth.value.setMonth(currentMonth.value.getMonth() + 1),
  );
};

const selectDate = (date) => {
  emit("select-date", date);
};
</script>

<style scoped></style>
