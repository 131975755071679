<template>
  <div
    class="flex h-screen w-screen items-center justify-center"
    v-if="!isAuthenticated"
  >
    <div class="absolute left-4 top-4">
      <h1 class="text-md text-[#6CCF8E]">GraFinance</h1>
    </div>
    <AppButton @click="login">Log in</AppButton>
  </div>
  <component v-else :is="currentView" />
</template>

<script setup>
import { ref, computed } from "vue";
import HomeView from "./views/HomeView.vue";
import AccountView from "./views/AccountView.vue";
import TransactionView from "./views/TransactionView.vue";
import NotFoundView from "./views/NotFoundView.vue";

import AppButton from "./components/AppButton.vue";

import { useAuth0 } from "@auth0/auth0-vue";

// Setup routes

const routes = {
  "/": HomeView,
  "/transaction": TransactionView,
  "/account": AccountView,
};

const currentPath = ref(window.location.hash);

window.addEventListener("hashchange", () => {
  currentPath.value = window.location.hash;
});

const currentView = computed(() => {
  return routes[currentPath.value.slice(1) || "/"] || NotFoundView;
});

// Handle authentication

const { loginWithRedirect, isAuthenticated } = useAuth0();

const login = () => loginWithRedirect();
</script>

<style></style>
