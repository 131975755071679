<template>
  <div class="flex h-screen w-full flex-col items-center justify-center gap-6">
    <AppButton @click="onClickTransaction">Create a transaction</AppButton>
    <AppButton @click="onClickAccount">Create an account</AppButton>
  </div>
</template>

<script setup>
import AppButton from "@/components/AppButton.vue";

const onClickTransaction = () => {
  window.location = "#/transaction";
};

const onClickAccount = () => {
  window.location = "#/account";
};
</script>

<style></style>
