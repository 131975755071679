<template>
  <AppField :label="label" :description="description">
    <input
      v-if="type === 'text'"
      type="text"
      class="h-8 rounded-sm border border-neutral-700 bg-neutral-900 pe-2 ps-2 font-normal shadow-none ring-0 hover:border-neutral-600 focus:border-neutral-700 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2 focus:ring-offset-[#181b1f]"
      v-model="model"
      @keypress="checkKey($event)"
    />
    <input
      v-else
      type="text"
      class="h-8 rounded-sm border border-neutral-700 bg-neutral-900 pe-2 ps-2 font-normal shadow-none ring-0 hover:border-neutral-600 focus:border-neutral-700 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2 focus:ring-offset-[#181b1f]"
      :placeholder="placeholder"
      v-model.number="model"
      @keypress="checkKey($event)"
    />
  </AppField>
</template>

<script setup>
import { defineProps, defineModel } from "vue";
import AppField from "./AppField.vue";

const model = defineModel();

const props = defineProps({
  label: {
    type: String,
    default: "Field",
  },
  type: {
    type: String,
    default: "text",
  },
  description: {
    type: String,
    default: "",
  },
  placeholder: {
    type: String,
    default: "",
  },
});

const checkKey = ($event) => {
  if (props.type === "number") {
    let keyCode = $event.keyCode ? $event.keyCode : $event.which;
    if ((keyCode < 48 || keyCode > 57) && keyCode !== 46) {
      // 46 is dot
      $event.preventDefault();
    }
  }
};
</script>

<style></style>
