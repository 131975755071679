<template>
  <div class="flex flex-col items-center gap-4 p-2">
    <div
      class="flex flex-col gap-4 rounded-md border border-blue-400 p-4 font-medium text-gray-300"
    >
      Create an account
      <div class="flex flex-col gap-4">
        <AppInput
          label="Name"
          type="string"
          placeholder="Name"
          v-model="accountName"
        ></AppInput>
      </div>
      <div class="flex justify-between">
        <AppButton secondary="" @click="accountName = ''">Reset</AppButton>
        <AppButton
          :disabled="!accountName || accountName === ''"
          @click="onCreate"
          >Create</AppButton
        >
      </div>
    </div>
  </div>
</template>

<script setup>
import { useAuth0 } from "@auth0/auth0-vue";
import { onMounted, ref } from "vue";
import AppButton from "../components/AppButton.vue";
import AppInput from "../components/AppInput.vue";

import { createAccount } from "../apis/accountAPI";

const { getAccessTokenSilently } = useAuth0();

// Variables
let accountName = ref(null);

let token = "";

// Methods

const onCreate = async () => {
  try {
    await createAccount(accountName.value, token);
    accountName.value = null;
  } catch (err) {
    console.error(err);
  }
};

const getToken = () => {
  getAccessTokenSilently()
    .then(async (t) => {
      token = t;
    })
    .catch(() => {
      console.log("Not logged yet");
    });
};

// LIfecycle Hooks

onMounted(async () => {
  getToken();
});
</script>

<style>
#app {
  font-family: Inter, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #181b1f;
  color: white;
  width: 100vw;
  min-height: 100vh;
}
</style>
